import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import About from "../components/About"

const IndexPage = ({ data }) => (
  <Layout about={true}>
    <SEO
      title={data.wordpressPage.title}
      keywords={[`gatsby`, `application`, `react`]}
    />

    <About
      projects={data.allWordpressWpProjects.edges}
      about={data.wordpressPage}
    />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    allWordpressWpProjects(
      filter: { wordpress_parent: { eq: 0 } }
      sort: { fields: menu_order, order: ASC }
    ) {
      edges {
        node {
          wordpress_id
          slug
          title
          wordpress_parent
          project_children {
            post_title
            post_name
          }
        }
      }
    }
    wordpressPage(slug: { eq: "about" }) {
      title
      content
    }
  }
`
