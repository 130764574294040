import React, { Component } from "react"
// import HomeNav from "./homeNav"

import { Link } from "gatsby"

import styled from "styled-components"

// import { Textfit } from "react-textfit"

const MenuButton = styled.div`
  position: fixed;
  top: 2rem;
  left: 2rem;
  z-index: 100;
  display: block;
  background: black;
  width: 10px;
  height: 10px;
  border: none;
  outline: none;
  /* text-indent: -9999px; */
  padding: 0;
  cursor: pointer;
  border-radius: 100%;
  a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }
  &:hover {
    background: red;
  }
  ${props =>
    props.open &&
    `
    background: white;
  `}

  @media only screen and (max-width: 500px) {
    top: 2rem;
    left: 1rem;
  }
`

const ProjectWrapper = styled.div`
  /* background: white; */
  width: 100%;
  /* height: 100vh; */
  ${props =>
    props.blur &&
    `
    background: black;
  `}
`

const InfoWrapper = styled.div`
  width: calc(100% - 12rem);
  /* position: absolute; */
  /* top: 4.5rem;
  left: 6rem; */
  text-align: justify;
  padding: 4rem;
  font-size: 1.5rem;
  line-height: 1.3;
  margin: 0 auto;
  padding-top: 6rem;
  background: none;
  ${props =>
    props.menuOpen &&
    `
    color:white;
    filter: blur(0.5rem);
  `}

  @media only screen and (max-width: 800px) {
    width: calc(100% - 2rem);
    padding: 3rem;
    left: 1rem;
    font-size: 1.3rem;
  }
  @media only screen and (max-width: 500px) {
    width: calc(100% - 1rem);
    padding: 1rem;
    left: 0.5rem;
    font-size: 1.1rem;
    padding-top: 5rem;
  }
`

class About extends Component {
  state = {
    infoOpen: false,
    menuOpen: false,
  }

  _handleMenuButton = () => {
    this.setState(prevState => ({
      menuOpen: !this.state.menuOpen,
    }))
  }

  render() {
    return (
      <ProjectWrapper blur={this.state.menuOpen}>
        <InfoWrapper menuOpen={this.state.menuOpen}>
          <div dangerouslySetInnerHTML={{ __html: this.props.about.content }} />
        </InfoWrapper>

        <MenuButton>
          <Link to="/" />
        </MenuButton>
      </ProjectWrapper>
    )
  }
}

export default About
